"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSupportedChainId = exports.assetToToken = exports.baseUrlFromChainId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const types_2 = require("../../types");
const baseUrlFromChainId = (chainId) => {
    switch (chainId) {
        case types_1.KnownChainIds.EthereumMainnet:
            return 'https://0x.shapeshift.com/ethereum/';
        case types_1.KnownChainIds.AvalancheMainnet:
            return 'https://0x.shapeshift.com/avalanche/';
        case types_1.KnownChainIds.OptimismMainnet:
            return 'https://0x.shapeshift.com/optimism/';
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return 'https://0x.shapeshift.com/bnbsmartchain/';
        case types_1.KnownChainIds.PolygonMainnet:
            return 'https://0x.shapeshift.com/polygon/';
        case types_1.KnownChainIds.ArbitrumMainnet:
            return 'https://0x.shapeshift.com/arbitrum/';
        case types_1.KnownChainIds.BaseMainnet:
            return 'https://0x.shapeshift.com/base/';
        default:
            (0, utils_1.assertUnreachable)(chainId);
    }
};
exports.baseUrlFromChainId = baseUrlFromChainId;
// converts an asset to zrx token (symbol or contract address)
const assetToToken = (asset) => {
    const { assetReference, assetNamespace } = (0, caip_1.fromAssetId)(asset.assetId);
    return assetNamespace === 'slip44' ? asset.symbol : assetReference;
};
exports.assetToToken = assetToToken;
const isSupportedChainId = (chainId) => {
    return types_2.zrxSupportedChainIds.includes(chainId);
};
exports.isSupportedChainId = isSupportedChainId;
