"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZRX_SUPPORTED_CHAIN_IDS = exports.ZRX_SUPPORTED_CHAINIDS = exports.ZRX_UNSUPPORTED_ASSETS = exports.AFFILIATE_ADDRESS = void 0;
const types_1 = require("@shapeshiftoss/types");
exports.AFFILIATE_ADDRESS = '0xc770eefad204b5180df6a14ee197d99d808ee52d';
// Zrx doesn't have an easily accessible master assets list.
// We assume all erc20's are supported and remove these explicitly unsupported assets
exports.ZRX_UNSUPPORTED_ASSETS = Object.freeze([
    // Foxy token unsupported by zrx
    'eip155:1/erc20:0xdc49108ce5c57bc3408c3a5e95f3d864ec386ed3',
    /**
     * ERC20 RUNE - we don't want people buying this instead of native RUNE
     * as it's exchangeable value for native RUNE is currently decaying from 1 towards 0
     */
    'eip155:1/erc20:0x3155ba85d5f96b2d030a4966af206230e46849cb',
]);
exports.ZRX_SUPPORTED_CHAINIDS = Object.freeze([
    types_1.KnownChainIds.EthereumMainnet,
    types_1.KnownChainIds.AvalancheMainnet,
    types_1.KnownChainIds.OptimismMainnet,
    types_1.KnownChainIds.BnbSmartChainMainnet,
    types_1.KnownChainIds.PolygonMainnet,
    types_1.KnownChainIds.ArbitrumMainnet,
    types_1.KnownChainIds.BaseMainnet,
]);
exports.ZRX_SUPPORTED_CHAIN_IDS = {
    sell: exports.ZRX_SUPPORTED_CHAINIDS,
    buy: exports.ZRX_SUPPORTED_CHAINIDS,
};
